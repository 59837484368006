import React from 'react';

import StandardPage from '../components/StandardPage';
import { LevelOne, LevelTwo } from '../components/OrderedLists';
import Head from '../components/Head';

export default () => (
  <StandardPage>
    <Head title="Terms of Service" description="" url="/terms/" image="/og_image.jpg" />
    <div className="section col ac jc bl60 pmargin">
      <div className="readable">
        <h2 className="title">Terms of Service</h2>
        <h4 className="title">Bagel Health, Inc.</h4>
        <p />
        <br />
        <ol type="I" className="title fs14">
          <LevelOne title="Covered Services">
            <li>
              Access and Use: Provided that You are bound by this Agreement, You may access and use
              the Bagel Health Service through the Site during the Service Term (as defined below)
              solely for your internal business purposes, including in connection with providing
              medical services to your patients, members, employees and/or customers (“Members”),
              subject to the terms and conditions of this Agreement and all applicable local, state,
              national and international laws, rules and regulations.
            </li>

            <li>
              Restrictions and Limitations: Your right to access and use the Bagel Health Service is
              nonexclusive, nontransferable, nonsublicenseable, and fully revocable. You shall have
              no right to, and shall not, reverse engineer, decompile, copy, modify or create
              derivative works of or based on, sell, resell, display, distribute, disseminate, rent
              or lease the Bagel Health Service or Site or any part thereof. Notwithstanding the
              foregoing, You may copy, print and use reports generated by the Bagel Health Service
              in accordance with the standard functionality of the Bagel Health Service. Access to
              and use of the Bagel Health Service is subject to the applicable terms and conditions
              of any agreement between Company and any third party regarding software or data from
              such third party that is contained within the Bagel Health Service.
            </li>

            <li>
              Reservation of Rights: Subject to the rights expressly granted hereunder, Company and
              its licensors retain all right, title and interest in and to the Bagel Health Service
              and the Site (including all information, content, software and other materials
              therein, excluding User Data) and in and to all of Company’s other intellectual
              property rights. No intellectual property or other rights or licenses are granted or
              otherwise provided by Company under this Agreement, by implication, estoppel or
              otherwise, beyond those expressly provided for herein. No software or copies thereof
              are sold, transferred, distributed, or licensed hereunder; instead only the limited
              right to access and use the Bagel Health Service, as expressly provided for in this
              Agreement, is granted hereunder.
            </li>

            <li>
              User Accounts: When You sign up or register with Company for use of the Bagel Health
              Service, You agree that all information provided to Company relating to You will be
              true, accurate, current and complete. You agree to promptly update all such
              information whenever any of it changes. You are entirely responsible for maintaining
              the confidentiality of your account information and password. You agree not to
              <LevelTwo>
                <li>
                  use the account, username, or password of another individual or entity when using
                  the Bagel Health Service, or
                </li>
                <li>
                  disclose your password to, or share your account with, any third party or allow or
                  authorize any third party to use your account or user ID with Company.
                </li>
              </LevelTwo>
              You agree to notify Company immediately if You suspect any unauthorized use of your
              account or access to your password or account. You are solely responsible for any and
              all use of your account.
            </li>
          </LevelOne>
          <LevelOne title="User Data">
            <li>
              User Data: All data, information, communications, text, files, photographs, graphics,
              video, messages or other materials or content, uploaded, submitted, posted or
              otherwise provided to the Bagel Health Service (and/or the Site) by or for You,
              including without limitation data relating to your Members and their interactions with
              You (collectively, “User Data”), shall be subject to this Agreement. You hereby
              confirm, represent and warrant that You have the full right and authority to provide
              the User Data to the Bagel Health Service (and Site), to store, analyze and otherwise
              use such User Data in the Bagel Health Service, and to grant Company the rights in
              such User Data set forth herein, without violating the intellectual property, privacy
              or other rights of any individuals or entities and without violating any applicable
              laws, rules or regulations. Under no circumstances will Company be liable in any way
              for any User Data, including any errors or omissions in any User Data, any
              modification, loss or deletion of any User Data or any or any loss or damage of any
              kind incurred as a result of the use of or reliance upon any User Data or the analysis
              thereof to the extent any loss, errors, damage, modifications, omissions, or deletion
              of User Data do not arise out of Company’s gross negligence or willful misconduct.
              Company warrants that no Protected Health Information (PHI) shall be stored,
              transmitted, or otherwise moved outside of the United States at any time, excluding
              any use by You of the Bagel Health Service from outside of the United States and
              excluding in connection with any administrative, customer support and similar use of
              the Bagel Health Service (or its backend systems) by Company or its personnel or third
              party service providers and contractors from outside the United States.
            </li>

            <li>
              Protection and Privacy of Your User Data: Your User Data is yours (and, if applicable,
              your Members’), not ours, and Company desires to prevent such User Data from being
              used by or disclosed to others. Company will protect the confidentiality and security
              of your User Data in accordance with Company's Privacy Policy posted on the Site,
              including through the use of appropriate administrative, physical and technical
              safeguards. Company reserves the right to use and disclose any User Data that is
              publicly available or becomes publicly available by you and Company shall not be
              liable with respect to any such disclosure. Company reserves the right to use and
              disclose any User Data to comply with applicable law or appropriate requests from
              governmental authorities, provided company shall provide reasonable notice to you
              prior to such use or disclosure and Company shall not be liable with respect to any
              such disclosure. You understand that through your use of the Bagel Health Service, You
              consent to the collection, storage and use of this information as described in this
              Agreement and in accordance with such Privacy Policy. The Bagel Health Service is
              designed to comply with the Health Insurance Portability and Accountability Act
              (“HIPAA”) with respect to any personal health information You upload or submit to the
              Bagel Health Service. Accordingly, You and Company are entering into a Business
              Associate Agreement in connection with this Agreement for purposes of establishing
              compliance with HIPAA.
            </li>

            <li>
              License to User Data: Subject to the restrictions described in the Privacy Policy
              above and under HIPAA (and the associated Business Associate Agreement), by uploading,
              submitting, posting or otherwise providing User Data on to, or through the Bagel
              Health Service or the Site, You agree that Company may use such User Data solely:
              <LevelTwo>
                <li>to operate, maintain and administer the Bagel Health Service, </li>
                <li>
                  to develop, modify, use and improve the Bagel Health Service and related products
                  and services, and{' '}
                </li>
                <li>
                  to publish aggregated and anonymized data, statistics and trend information.
                </li>
              </LevelTwo>
            </li>
            <li>
              You, or your Members and third party licensors, as applicable, retain any intellectual
              property rights in the User Data You, subject to the foregoing license.
            </li>
          </LevelOne>
          <LevelOne title="Fees and Payment Terms">
            <li>
              Service and Usage Fees: Company may offer the Bagel Health Service, or certain
              features or functionality thereof, free of charge. Company may also choose at any time
              to charge one or more fees (“Fees”) for use of the Bagel Health Service or for certain
              features or functionality thereof, in its sole discretion, by indicating such Fees on
              the Site or in other applicable order documentation or notices when You first sign up
              for the Bagel Health Service or otherwise in applicable notifications to you before
              such Fees are imposed (any such postings, notices or documentation being an “Order”).
              You agree to pay to Company any Fees imposed with respect to your use of the Bagel
              Health Service, if any, in accordance with the applicable Order. All Fee payment
              obligations are non-cancelable and all Fees paid are non-refundable and
              non-creditable. Company may change any or all of its Fees hereunder (or impose new
              Fees) upon posting such changed or new Fees within or in connection with the Bagel
              Health Service. Such Fees shall automatically go into effect upon their posting (and
              become the Fees payable hereunder), unless and until You terminate this Agreement and
              your use and access to the Bagel Health Service prior to such time in accordance with
              the termination provisions below. Your continued use of the Bagel Health Service after
              the posting of changed or new fees shall constitute your acceptance of such change.
            </li>

            <li>
              Payment Terms: You shall pay all other Fees at the times specified in the Order or, if
              not so specified, within thirty (30) days of Company’s invoice with respect to any
              such Fees. All Fees are payable in United State dollars by the form of payment
              indicated on the service order, which You may be asked to provide upon sign-up or
              registration for the Bagel Health Service, unless the Order states otherwise. You
              agree to keep the credit card (or other charge account) information you submitted to
              Company accurate and up to date. Any Fees that are not received from You by the due
              date therefor will accrue interest at the rate of 1.5% of the outstanding balance per
              month, or the maximum rate permitted by law, whichever is lower, from the date such
              payment is due until the date paid.
            </li>

            <li>
              Taxes: You are responsible for paying (and all Fees are exclusive of) any taxes,
              levies, duties or similar governmental assessments of any nature associated with your
              use of or access to the Bagel Health Service or the payment of Fees under this
              Agreement (other than taxes on Company’s net income). If Company has the legal
              obligation to pay or collect taxes, levies, duties or similar assessments for which
              You are responsible hereunder, Company may add the appropriate amount to amounts
              charged to and paid by You hereunder.
            </li>
          </LevelOne>
          <LevelOne title="Term, Termination, and Modification of Agreement">
            <li>
              Service Term: The term of your Bagel Health Service (the “Service Term”) shall
              commence on the date You accept this Agreement through selection or clicking and shall
              terminate upon the designated termination date, or at the end of the designated period
              of time indicated for such term, in the Order (if any), or, if earlier, upon the
              effective date of termination of such Bagel Health Service as specified below.
            </li>

            <li>
              Termination By User: You may terminate your Bagel Health Service at any time for any
              reason (or no reason) upon thirty (30) days prior written notice to Company, which
              termination shall become effective upon the end of such thirty-day notice period,
              unless You have a paid subscription, in which case such termination shall occur at the
              end of any then-current periodic subscription period as specified in the Order or, if
              not so specified, the end of the next full periodic subscription period (and You shall
              remain obligated to pay any Fees, including periodic subscription fees, that become
              due during such thirty-day, or longer, period). After receipt of your termination
              notice, Company will terminate your Bagel Health Service account upon the effective
              date of termination. Termination of your Bagel Health Service shall not relieve You of
              any obligation that has accrued or existed prior to termination or any obligation to
              pay any accrued Fees.
            </li>

            <li>
              Termination By Company: Company may terminate your Bagel Health Service at any time
              for any reason (or no reason) upon thirty (30) days prior written notice to You, which
              termination shall become effective upon the end of such thirty-day notice period,
              unless You have a paid periodic subscription to the Bagel Health Service, in which
              case such termination shall become effective upon the later to occur of
              <LevelTwo>
                <li>the end of such thirty-day notice period, or</li>
                <li>
                  the end of any then-current periodic subscription period as specified in the Order
                  or,
                </li>
                <li>
                  if not so specified, the end of the next full monthly subscription period (and You
                  shall remain obligated to pay any Fees, including periodic subscription fees, that
                  become due during such thirty (30) day, or longer, period).
                </li>
              </LevelTwo>
            </li>

            <li>
              Furthermore, and without limiting any other remedies, Company may terminate your Bagel
              Health Service in the event that You breach this Agreement (including upon any failure
              to pay Fees when due) upon fifteen (15) days notice, unless You have cured such breach
              before the end of such fifteen day period. Company reserves the right to immediately
              suspend your access to and use of the Bagel Health Service in the event of any breach
              of this Agreement which threatens immediate harm to the Bagel Health Service or in the
              event of any violation of law, a serious security issue or other emergency situation.
              In the event of any termination by Company, Company will terminate your Bagel Health
              Service account upon the effective date of termination. Termination of your service
              shall not relieve You of any obligation that has accrued or existed prior to
              termination, nor of any obligation to pay any accrued Fees.
            </li>

            <li>
              Effect of Termination: Upon any termination of your Bagel Health Service by You or
              Company, You understand and acknowledge that Company will have no further obligation
              to provide the Bagel Health Service or access thereto nor any obligation to refund,
              return or credit any amounts paid or payable to Company. Upon termination, all
              licenses and other rights granted to You by this Agreement will immediately cease and
              You shall cease using and cease accessing the Bagel Health Service. Company is not
              liable to You or any third party for termination of the Bagel Health Service or
              termination of your use of the Bagel Health Service or the Site.
              <LevelTwo>
                <li>
                  User Data. You are encouraged to retain copies of all your User Data; however, for
                  a period of sixty (60) days after the effective date of termination, Company will
                  make the User Data contained in the Bagel Health Service as of such termination
                  date available to You upon written request prior to the end of such sixty day
                  period. Except as expressly provided in the previous sentence (or as required by
                  hipaa or other applicable laws), upon any termination, any user data that you have
                  submitted, posted, uploaded or otherwise provided to the Bagel Health Service may
                  no longer be accessed by you and, upon written request, will be destroyed or
                  returned unless Company is legally obligated to maintain such User Data or
                  destruction/return is not feasible. If Company cannot return or destroy all data,
                  it shall provide you a written explanation detailing why destruction or return is
                  not feasible. Furthermore, except as may be required by applicable law, Company
                  will have no obligation to (but may in its sole discretion) store or maintain any
                  User Data or other information stored in the Bagel Health Service or any other
                  database related to your account or to forward any information to You or any third
                  party.
                </li>
                <li>
                  Agreement Terms. Upon any termination of your Bagel Health Service, Sections 1.3,
                  1.4, 2.3, 4.4 and 5 through 8 of this Agreement shall survive and remain in full
                  force and effect. Furthermore, any of your obligations to Company under this
                  Agreement which have accrued prior to such termination, and Section 3 with respect
                  to any Fees due or payable prior to termination, shall survive such termination
                  and remain in full force and effect. All other provisions of this agreement shall
                  terminate upon such termination of your Bagel Health Service and shall thereupon
                  be of no force or effect.
                </li>
              </LevelTwo>
            </li>
            <li>
              Modification of Bagel Health Service or Agreement By Company: Company reserves the
              right, in its sole discretion, at any time to modify, augment or limit the Bagel
              Health Service (or the Site) without advance notice. All modifications and additions
              to the Bagel Health Service (and/or Site) shall be governed by this Agreement, unless
              otherwise expressly stated by Company in writing. Company may also modify or amend
              this Agreement in its sole discretion at any time by posting the modifications or the
              amended Agreement on the Site and providing you with written notice thereof. All
              modified terms and conditions and amended Agreements will be effective thirty (30)
              days after such notice (unless a longer notice period is required by applicable law),
              unless You first give Company written notice of rejection of the modifications or
              amended Agreement within such thirty day period. In the event of such rejection, this
              Agreement will continue under its original provisions until the Agreement is
              terminated as provided above (or subsequently amended in accordance with this
              Section). If any modified terms and conditions are not acceptable to You, your sole
              remedy is to timely provide the foregoing rejection notice or to cease using the Bagel
              Health Service (and Site), and if applicable, terminate your Bagel Health Service as
              provided above. By continuing to access or use the Bagel Health Service without
              providing a timely rejection notice after Company provides notice of any such revision
              or amendment, You agree to be bound by the revised Agreement. This Agreement may not
              otherwise be modified or amended, except with the express written agreement of both
              parties. Company may separately amend the Privacy Policy in accordance with the
              provisions thereof.
            </li>
          </LevelOne>
          <LevelOne title="Disclaimer of Warranties and Member Responsibility">
            <li>
              You Are Solely Responsible for Your Members: You are solely responsible and liable for
              your Members and their use of the Bagel Health Service and Company hereby disclaims
              responsibility or liability with respect thereto. Without limiting the foregoing, You
              are responsible for any support, training and communication with and for your Members
              with respect to the Bagel Health Service. You are also responsible for ensuring Your
              Members comply with the applicable terms and conditions of this Agreement with respect
              to their use of the Service and any breach by such Members of such terms and
              conditions shall be deemed a breach by You hereof.
            </li>

            <li>
              Information Provided on an “As Is” Basis; Assumption of Risk: You acknowledge that you
              are fully assuming the risks arising from the use of any data, articles, publications,
              content, materials, reports, analyses and other information generated by, available
              on, or otherwise provided through the Bagel Health Service or the Site, whether or not
              originating from third party sources (collectively, “Bagel Health Service Content”).
              Such risks shall include, but are not limited to, erroneous, inaccurate or invalid
              results or information, omissions, misrepresentations, and similar issues. You agree
              that Company shall not be liable or responsible for any damages, liabilities, costs,
              harms, business disruptions or expenditures of any kind that may arise from or in
              connection with your use or application of any Bagel Health Service Content. All Bagel
              Health Service Content is provided for your convenience only on an “as is” basis
              without warranty of any kind. Company does not warrant, guarantee, endorse, support,
              represent, verify or otherwise have any responsibility for the completeness,
              truthfulness, accuracy or reliability of any Bagel Health Service Content. All use of
              and reliance upon any such information shall be solely your responsibility and at your
              risk. Without limiting the foregoing, no analyses, reports or other information
              obtained or provided through the Bagel Health Service or the Site are intended to be
              (nor should be relied upon as) medical advice or instructions for medical diagnosis or
              treatment. The Bagel Health Service and Site may contain links to third party websites
              or resources. You acknowledge and agree that we are not responsible or liable for the
              availability or accuracy of such websites or resources or the content, products or
              services on or available therefrom. Links to such websites and resources do not imply
              any endorsement by Company thereof or the content, products or services thereon. You
              acknowledge sole responsibility for and assume all risk arising from your use of any
              such websites or resources.
            </li>

            <li>
              Disclaimer of Warranties. You expressly understand and agree that:
              <br />
              Your use of the Bagel Health Service, site, Bagel Health Service content and user data
              is at your sole risk and Company shall not be responsible for any inability to use, or
              any delays, errors or omissions with respect to, the Bagel Health Service or site. The
              Bagel Health Service, site, Bagel Health Service content and user data and all
              materials, information, products and services included therein, are provided on an "as
              is" and "as available" basis. Company expressly disclaims all warranties and
              representations of any kind, whether express or implied, relating to the Bagel Health
              Service, site, Bagel Health Service content and user data, including, but not limited
              to the implied warranties of merchantability, fitness for a particular purpose and
              non-infringement.
              <br />
              Company makes no warranty that
              <LevelTwo>
                <li>the Bagel Health Service or site will meet your requirements,</li>
                <li>
                  the Bagel Health Service or site will be uninterrupted, available for use at any
                  given time, timely, secure, or error-free,
                </li>
                <li>
                  the results or other Bagel Health Service content that may be obtained from the
                  use of the Bagel Health Service or site will be accurate or reliable or otherwise
                  meet your expectations, nor that
                </li>
                <li>any errors in the Bagel Health Service or site will be corrected.</li>
              </LevelTwo>
            </li>
          </LevelOne>
          <LevelOne title="Indemnification">
            <li>
              You agree to defend, indemnify, and hold Company, its officers, directors, employees
              and agents, harmless from and against any and all claims, liabilities, damages,
              losses, and expenses, including without limitation reasonable attorney's fees and
              costs, arising out of or in any way connected with
              <LevelTwo>
                <li>
                  your access to or use of the Bagel Health Service, Site, Bagel Health Service
                  Content and User Data;
                </li>
                <li>your violation of the Agreement;</li>
                <li>
                  your violation of any applicable laws, rules or regulations in connection with
                  your use of the Bagel Health Service or any Bagel Health Service Content or User
                  Data;
                </li>
                <li>any User Data submitted, posted, uploaded or provided by You;</li>
                <li>any claim, action or proceeding brought by or for any Members; or</li>
                <li>
                  your violation of any third party right, including without limitation any
                  intellectual property, publicity, confidentiality, property or privacy right in
                  connection with your use of the Bagel Health Service or any Bagel Health Service
                  Content or User Data.
                </li>
              </LevelTwo>
            </li>
          </LevelOne>
          <LevelOne title="Limitation of Liability">
            <li>
              You expressly understand and agree that, to the maximum extent permitted by applicable
              law, Company, and its affiliates, officers, directors, employees, agents and
              licensors, shall not be liable for any indirect, incidental, special, consequential or
              exemplary damages, arising out of or in connection with this agreement or your use of
              the Bagel Health Service, site, Bagel Health Service content, and user data, including
              but not limited to, damages for loss of profits, goodwill, use, data or other
              intangible losses (even if company has been advised of the possibility of such
              damages). In no event will Company's (or its affiliates’, officers’, directors’,
              employees’, agents’ and licensors’) cumulative liability to you exceed ten thousand
              dollars (us $10,000) in direct damages, except to the extent such limitation is not
              permitted by applicable law.
            </li>
            <li>
              Notwithstanding the foregoing, these limitations on liability do not apply to any
              violation by Company of the Health Insurance Portability and Accountability Act of
              1996 (as amended) and the regulations promulgated thereunder (HIPAA) for which Bagel
              shall be solely responsible to the extent such violation is due to the negligence,
              willful misconduct, or breach of these terms by Bagel.
            </li>

            <li>
              Some jurisdictions do not allow the exclusion of certain warranties or the exclusion
              or limitation of liability for consequential or incidental damages, so the limitations
              above may not apply to You to the extent applicable law so requires.
            </li>
          </LevelOne>
          <LevelOne title="General Information">
            <li>
              The Agreement (including any terms and conditions expressly incorporated herein by
              reference) constitutes the entire agreement between You and Company with respect to
              the subject matter hereof and governs your use of the Bagel Health Service and the
              Site, superseding any prior and contemporaneous agreements, understandings or
              arrangements between You and Company with respect to such subject matter. Without
              limiting the foregoing, this Agreement supersedes all terms contained in any purchase
              order, invoice or other ordering document. You will not assign the Agreement or assign
              any rights or delegate any obligations hereunder, in whole or in part, whether
              voluntarily or by operation of law, without the prior written consent of Company. Any
              purported assignment or delegation by You without the appropriate prior written
              consent of Company will be null and void. Company may assign the Agreement or any
              rights hereunder (or delegate any obligations hereunder) without your consent. The
              Agreement and the relationship between You and Company shall be governed by the laws
              of the State of California, without regard to or application of its conflict of law
              provisions, rules and principles. You hereby agree that the state and federal courts
              of California shall have jurisdiction and venue to resolve disputes under or with
              respect to this Agreement and You hereby consent to such jurisdiction and venue. The
              failure of Company to exercise or enforce any right, remedy or provision of the
              Agreement shall not constitute a waiver of such right, remedy or provision. If any
              provision of the Agreement is found by a court of competent jurisdiction to be
              invalid, the parties nevertheless agree that the court should endeavor to give effect
              to the parties' intentions as reflected in the provision to the full extent consistent
              with applicable law, and the other provisions of the Agreement shall remain in full
              force and effect. You agree that regardless of any statute or law to the contrary, any
              claim or cause of action brought by You arising out of or related to use of the
              Services or the Agreement must be filed within two (2) years after such claim or cause
              of action arose or be forever barred. You and Company are independent contractors and
              no agency, partnership, joint venture, employee-employer or franchiser-franchisee
              relationship is intended or created by this Agreement. The section titles in the
              Agreement are for convenience only and have no legal or contractual effect. The Bagel
              Health Service may be subject to United States export laws and regulations, and
              foreign equivalents (which may include restrictions on destinations, end users and end
              use), and You agree to comply with all such applicable laws and regulations.
            </li>
            <li>
              Company may provide notices to You with respect to this Agreement or the Bagel Health
              Service by posting such notices to your Bagel Health Service account or by sending
              them to the e-mail address or other contact address You provide upon sign-up,
              registration or setting up your account. Any such notices shall be deemed properly and
              timely given to You hereunder. You consent to the use of:
              <LevelTwo>
                <li>
                  electronic means to complete this Agreement and to provide You with any notices
                  given pursuant to this Agreement; and
                </li>
                <li>
                  electronic records to store information related to this Agreement or your use of
                  the Bagel Health Service and the Site.
                </li>
              </LevelTwo>
            </li>
          </LevelOne>
          <LevelOne title="Violations and Comments">
            <li>
              Please report any violations of the Agreement or provide any comments or questions,
              including any technical questions regarding the Bagel Health Service, by emailing us
              at team@bagel.md. You agree, however, that:
            </li>
            <LevelTwo>
              <li>
                by submitting ideas regarding the Bagel Health Service or Site to Company or any of
                its employees or representatives, You automatically grant Company a license to any
                of your intellectual property rights in these ideas; and
              </li>
              <li>
                ideas regarding the Bagel Health Service or the Site submitted to Company or any of
                its employees or representatives automatically become licensed to Company.
              </li>
            </LevelTwo>
            <li>
              You hereby grant to Company a royalty-free, worldwide, irrevocable, perpetual,
              sublicenseable license to use and incorporate into the Bagel Health Service and the
              Site any suggestions, enhancement requests, comments, recommendations or other
              feedback provided by You relating to the Bagel Health Service or Site or the operation
              or functionality thereof.
            </li>
          </LevelOne>
        </ol>
        <p className="small bl40 mt32">Updated 6 December, 2018</p>
      </div>
    </div>
  </StandardPage>
);
