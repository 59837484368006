import React from 'react';
export const LevelOne = ({ title, children }) => {
  return (
    <li className="mt32">
      <div className="title bl40">{title}</div>
      <br />
      <ol type="1" className="fs10 ml16">
        {children}
      </ol>
    </li>
  );
};
export const LevelTwo = ({ children }) => {
  return (
    <ol type="a" className="fs10 ml16 mt8">
      {children}
    </ol>
  );
};
